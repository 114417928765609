import Vue from 'vue'
import Vant from 'vant'
import 'vant/lib/index.css'
import App from './App.vue'
import router from './router'
import vueWechatTitle from 'vue-wechat-title'
import VueAMap from 'vue-amap'

VueAMap.initAMapApiLoader({
  key: 'e79b02bdb3e14d927fa28d41623c330a',
  plugin: [
    'AMap.CircleEditor',
    'AMap.Geolocation',
    'AMap.Geocoder',
    'AMap.Autocomplete',
    'AMap.PlaceSearch',
    'AMap.CitySearch'
  ]
})

window._AMapSecurityConfig = {
  securityJsCode: 'a5c1899393be65967afc8f018751b6ec'
}
Vue.use(VueAMap)
Vue.use(Vant)
Vue.use(vueWechatTitle)

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
