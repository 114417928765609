import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: () => import('../views/index.vue'),
    meta: { title: '梁子大河蟹' }
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue'),
    meta: { title: '详情' }
  },
  {
    path: '/information',
    name: 'details',
    component: () => import('../views/information.vue'),
    meta: { title: '详情' }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log(to.path)
  if (to.path === '/index') {
    next('/')
  } else {
    next()
  }
})

export default router
