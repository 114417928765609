<template>
  <div id="app">
    <router-view class="child-view"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f9f9f9;
  // height: 100vh;
}
body,
html {
  background-color: #f9f9f9;
}
</style>
